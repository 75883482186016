<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      dark
      app   
      width="60"         
    >
      <v-list>
        <v-list-item to="/account" link class="px-2" :ripple="false">
            <v-list-item-avatar to="/calendar" link>
                <v-img src="../assets/avatars/hotdog-01.png"></v-img>
            </v-list-item-avatar>
        </v-list-item>

        <v-list-item
            v-for="item in items" 
            :key="item.label"
            :to="item.route"
            :ripple="false"
            link            
        >
            <v-list-item-icon :to="item.route">
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>

             <!-- <v-list-item-content>
                <v-list-item-title :to="item.route">{{ item.label }}</v-list-item-title>
            </v-list-item-content> -->
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark color="primary">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Le Application</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </div>
</template>

<script>
export default {
    name: 'uxDesktop',
    data() {
        return {
            drawer: null,
            items: [
                { 
                    label: "Home",
                    route:  "/home",
                    icon: 'mdi-home',
                    color: '#2196F3'
                },
                { 
                    label : "Dashboard", 
                    route : '/dashboard', 
                    icon : "mdi-view-dashboard"
                },                
                { 
                    label: "About",
                    route:  "/about",
                    icon: 'mdi-information',
                    color: '#3F51B5'
                }
            ]
        }
    },
    methods: {

    },
    created() {
        if (this.$route.path != "/home") {
            this.$router.push('/home');      
        }  
    }
}
</script>

<style>

</style>