import Vue from 'vue'
import VueRouter from 'vue-router'

import CalendarNew from "../components/CalendarNew"
import Dashboard from "../components/Dashboard"
import Account from "../components/Account"
import Menu from "../components/Menu"
import Home from "../components/Home"
import Reminders from "../components/Reminders"

Vue.use(VueRouter)

const routes = [
    {
        path: '/dashboard/',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/account',
        name: 'Account',
        component: Account
    },
    {
        path: '/menu',
        name: 'Menu',
        component: Menu
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/reminders',
        name: 'Reminders',
        component: Reminders
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: CalendarNew
    }
    
]

const router = new VueRouter({
    routes
});
  
export default router

function checkPermission(to, from, next) {
    // Check if user has permission
    // if (auth.hasPermission(to.name)) {
    //     // Redirect to path
    //     next();
    // } else {
    //     // Otherwise redirect to home dashboard
    //     next({
    //         path: '/'
    //     })
    // }
    next({
      path: '/'
    })
  }