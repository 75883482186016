<template>  
  <v-app>    
    <SignIn v-if="!authenticated" v-on:isAuthenticated="setAuthState($event)"/>
    <Init v-else />              
    <!-- <Interface v-else />               -->
  </v-app>
</template>

<script>
import { User } from '@/User';
// import Interface from './components/Interface'
import Init from './Init.vue'
import SignIn from './components/SignIn'

export default {
  name: 'App',
  components: {    
    Init,
    SignIn
  },

  data() {
    return {      
      authenticated: false,
      intervalID: null,
      updateRefreshIntervalMS: 30000,
    }
  },
  methods: {   
    setAuthState(event) {      
      this.authenticated = event;
    },
    setBackground(type) {
      
      // THIS DOES NOT WORK... YET

      // TODO failover to 'default' if class does not exist
      // document.getElementById("app").className = type;
      // document.getElementById("app").style.backgroundImage = "url('./assets/backgrounds/dawn.jpg')";      
      
      // failover
      // document.getElementById("app").className = "default";           
    },
    updateBackground() {
      return
    }
  },
  mounted() {
    let user = new User;
    this.authenticated = user.valid; 
    console.log("App.vue mounted(); authenticated:", this.authenticated)   
    // this.setBackground("morning");
  },
  created() {           
      // this.setCurrentDate();
      // this.setCurrentTime();
      // this.refreshDateTime();
      // this.updateBackground()      
    },
    beforeDestroy() {
        clearInterval(this.intervalID);
    }
};
</script>

<style>
.default {
  background: #999;
}

.morning {
  background: url('./assets/backgrounds/morning.jpg');
}

#app {  
  background: url('./assets/backgrounds/morning.jpg');
  background-size: cover;  
}
</style>
