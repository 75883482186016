<template>
  <div>      
    <uxDesktop v-if="desktopUX" />
    <uxMobile v-else />
  </div>
</template>

<script>
import uxDesktop from './components/uxDesktop.vue'
import uxMobile from './components/uxMobile.vue'

export default {
    name: 'Init',
    components: {
      uxDesktop,
      uxMobile
    },
    data() {
      return {
        desktopUX: true,
        mobileWidthMax: 760
      }
    },
    methods: {
      onResize() {
        window.addEventListener("resize", () => {
          this.detectUX();
        });
        // arrow function is same as...
        // let that = this;
        // window.addEventListener("resize", function(){
        //   that.updateScreenWidth();
        // });
      },
      detectUX() {                
        this.desktopUX = window.innerWidth <= this.mobileWidthMax ? false : true;
      } 
    },
    mounted() {      
      this.detectUX();
      this.onResize();      
    }
}
</script>


<style lang="scss">
</style>
