<template>   
    <div>

        <!-- dialog -->
        <v-dialog
                v-model="dialog"                
        >
            <v-card
                elevation="2"
                :loading="updateInProgress"                
            >
                <v-card-title class="menuTitle"  :style="{ 'text-transform': menuTitleCase }">Edit Menu</v-card-title>
                <v-card-text>
                    <div v-for="item in menu" :key="item.weekday">
                        <v-row>                           <!-- blue lighten-3 -->
                            <v-col cols="12" :class="resolveClass(item)">
                                <v-text-field                                                                        
                                    :label="weekdays[item.weekday]" 
                                    v-model="item.dinner"                                    
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>                        
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>                   
                    <v-btn                                             
                        @click="dialog = false" 
                        text               
                    >
                        Close
                    </v-btn>
                    <v-btn                                              
                        @click="updateMenu" 
                        :loading="updateInProgress"
                        dark                
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>            
        </v-dialog>
        <!-- end dialog -->

        <v-card v-if="summary" :dark="settings.darkMode">
            <v-card-title>
                Menu
            </v-card-title>
            <v-card-subtitle>
                {{ todayDinner }}
            </v-card-subtitle>
        </v-card>
        
        <v-card v-else>
            <v-card-title class="menuTitle" :style="{ 'text-transform': menuTitleCase }">
                    {{ settings.title }}
                </v-card-title>
                <v-card-text>                
                        <v-row>
                            <v-col cols="12" v-if="menu.length > 0">
                                <v-list disabled>
                                    <v-list-item-group
                                        v-model="todayIndex"
                                        color="primary"
                                    >
                                        <v-list-item v-for="item in menu" :key="item.weekday">   
                                            <span
                                                class="ma-2 menuTitle"                                        
                                                :color="item.weekday == todayIndex ? 'primary' : 'default'"
                                                :style="{ 'text-transform' : weekdayTitleCase }"
                                                >
                                                {{ weekdayName([item.weekday]) }}
                                            </span>  
                                            {{ item.dinner }}                                                        
                                        </v-list-item>
                                    </v-list-item-group>  
                                </v-list>
                            </v-col>
                            <v-col cols="12" v-else>
                                No menu items.
                            </v-col>
                        </v-row>
                                    
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn icon color="primary" @click="dialog = true" :disabled="account.uid == null">
                            <v-icon>mdi-pencil</v-icon>                                
                        </v-btn>                        
                    </v-card-actions>
                    
                </v-card-text>
        </v-card>

    </div>   
</template>

<script>
// import { db, collection, doc, getDocs, setDoc } from '@/fb-db';
import { db, collection, doc, getDoc, addDoc, setDoc } from '@/fb-db';
import { User } from '@/User';

export default {
    Name: 'Menu',
    props: {
        summary: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            account:{
                uid : null
            },
            settings: {
                darkMode: false,
                title: "Le Menu",
                titleUppercase: false,
                shortWeekdayNames: true,
                weekdaysUppercase: true
            },
            weekdays: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            menu: [],        
            todayMenu : {},    
            dialog: false,
            updateInProgress: false,
            todayIndex: null,
            timer: null            
        }
    },
    methods: {
        generateRandHex(size) {            
            let generated = [];
            let randHex = null;

            for(let x=0; x<size; x++) {                              
                generated.push( Math.floor(Math.random() * 16).toString(16) );
            }
            
            randHex = generated.join("");
            return randHex;
        },
        generateID() {
            let now = new Date;
            let epoch = now.getTime(); 
            let hex = this.generateRandHex(16);           
            let id = epoch.toString() + "-" + hex;
            return id;
        },
        async addItem() {   
            // deprecated, but notes below remain for API reference for now...         
            await setDoc(doc(db, "menu", this.account.uid), {
                uid: this.account.uid,
                id: this.generateID(),
                menu: [
                    { 
                        weekday: 0, 
                        dinner: "Steak"
                    },
                    {
                        weekday: 1,
                        dinner: "Sausage"
                    }
                ]
            }, { merge: true});

            // auto ID for document
            // const docRef = await addDoc(collection(db, "cities"), {
            //     name: "Tokyo",
            //     country: "Japan"
            // });
            // console.log("Document written with ID: ", docRef.id);

        },        
        async getData(db) {
            // const uid = "PASTE UID HERE";
            // const settingsColl = collection(db, 'menu'); 
            // const settingsDocs = await getDocs(settingsColl);
            // const snizzList = settingsDocs.docs.map(doc => doc.data());
            // console.log(snizzList);            
            // TODO: obtain UID from session storage object          

            const docRef = doc(db, "menu", this.account.uid);
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();

            if (data.menu) {   
                this.initializeMenu(data.menu);                                 
            }
        },
        initializeMenu(data) {                
                let weekdays = 7;
                let x;

                // initialize menu
                for(x=0; x<weekdays; x++) {
                    this.menu.push({
                        dinner: "",
                        weekday: x
                    });
                }

                for(x=0; x<data.length; x++) {
                    let currItem = data[x];
                    this.menu[currItem.weekday] = currItem;
                }         
                
                this.todayMenu = this.menu.length >= this.todayIndex ? this.menu[this.todayIndex] : { "dinner" : "No menu item set for today", "weekday" : this.todayIndex }

                /* hmmm */
                this.$emit('menuItems', this.menu);    
        },
        async updateMenu() {
            this.updateInProgress = true;

            await setDoc(doc(db, "menu", this.account.uid), {                
                id: this.generateID(),
                menu: this.menu
            }, { merge: true});

            // TODO: check result, display message
            this.updateInProgress = false;            
        },
        resolveWeekdayIndex() {
            let now = new Date;
            this.todayIndex = now.getDay();                    
        },
        weekdayName(index) {
            let weekday = this.weekdays[index];
            weekday = this.settings.shortWeekdayNames ? weekday.substring(0, 3) : weekday;            
            return weekday;
        },
        resolveClass(item) {
            return item.weekday == this.todayIndex ? "currentWeekday" : "inactiveWeekday"
        },
        stopTimer() {            
            clearInterval(this.timer);
        }
    }, 
    computed: {
        menuTitleCase() {
            return this.settings.titleUppercase ? "uppercase" : "none"
        },
        weekdayTitleCase() {
            return this.settings.weekdaysUppercase ? "uppercase" : "none"
        },
        todayDinner() {
            return this.todayMenu.dinner
        }
    },
    created() {
        this.resolveWeekdayIndex();
        this.timer = setInterval(this.resolveWeekdayIndex, 30000); // check every 30 seconds          
    },
    mounted() {
        let user = new User();
        this.account.uid = user.uid ? user.uid : null;

        this.getData(db);            
    },
    beforeDestroy() {
        this.stopTimer();
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');

.menuBlack {
    background: #000;
    color: #E3F2FD
}

.menuTitle {
    font-family: 'Fredericka the Great', cursive;
    text-transform: none;
}

.currentWeekday {
    background-color: #E3F2FD
}

.inactiveWeekday {
    background-color: white
}
</style>