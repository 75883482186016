<template>
    <v-card>
        <v-card-title>Weather</v-card-title>
        <v-card-subtitle>{{ weatherData.name }}, {{ weatherData.sys.country }}</v-card-subtitle>

        <v-card-text v-if="summary">
            <v-row>            
                <v-col cols="2">
                    <v-img :src="currentConditionsIcon" height="50" width="50"></v-img>                    
                </v-col>
                <v-col cols="10">
                    <span class="float-left">
                        {{ temperatureCurrent }}<sup>o</sup>C
                        {{ titleCase(currentConditionsDetail) }} <br>
                    Last updated {{ lastUpdatedDateTime }}
                    </span>
                </v-col>        
            </v-row>
        </v-card-text>

        <v-card-text v-else>
            <v-row>
                <v-col cols="3">
                    <v-img :src="currentConditionsIcon"></v-img>
                </v-col>
                <v-col cols="9">
                    <span class="float-left heading">
                        {{ temperatureCurrent }}<sup>o</sup>C
                    </span>
                </v-col>
            </v-row>
            <!-- <div class="current-temp" style="border: 1px solid #f00;">
                    <img :src="currentConditionsIcon" width="50px" height="50px"> 
                    <span class="heading">{{ temperatureCurrent }}<sup>o</sup>C</span>
                </div> -->
            <p>
                    {{ titleCase(currentConditionsDetail) }} <br>
                    Last updated {{ lastUpdatedDateTime }}
            </p>
                <v-row>
                    <v-col cols="4">
                        Current: {{ temperatureCurrent }}&#8451;
                    </v-col>
                    <v-col cols="4">
                        Low: {{ temperatureLow }}&#8451;
                    </v-col>
                    <v-col cols="4">
                        High: {{ temperatureHigh }}<sup>o</sup>C
                    </v-col>
                    <v-col cols="12" v-if="debug.show">
                        {{ debug.timerInfo }}
                    </v-col>
                </v-row>
                
                <v-row v-if="debug.show">
                    <v-col cols="12">
                        {{ currentConditions }} <br />
                        {{ currentConditionsDetail }}
                    </v-col>
                </v-row>

                <v-row v-if="debug.show">
                    <v-col cols="12">
                        {{ weatherData }}
                    </v-col>
                </v-row>
        </v-card-text>
    </v-card>    
</template>

<script>
export default {
    Name: 'Weather',
    props: {
        summary: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            apiKey : 'f1466f9bc7013887772719a57e4968db',
            lat : '42.98',
            long : '-81.24',
            urlAPI : 'https://api.openweathermap.org/data/2.5/weather?q=London,ca&units=metric&APPID={API key}',
            urlIcon : 'https://openweathermap.org/img/w/',
            weatherInfo : '',
            weatherData : {
                name : null,
                sys : {
                    country : null
                }
            },
            lastUpdated: null,
            updateIntervalMS: 600000,
            intervalRef : null,
            lastUpdatedDateTime : null,
            simulateResponse : false,
            temperatureCurrent : null,
            temperatureLow : null,
            temperatureHigh : null,
            currentConditions : null,
            currentConditionsDetail : null,
            currentConditionsIcon : null,
            debug : {
                timerInfo : "",
                show: false
            }
        }
    },
    methods: {
        async getWeather() {
            let urlAPI = this.urlAPI;
            let that = this;

            urlAPI = urlAPI.replace("{API key}", this.apiKey);
            
            await fetch(urlAPI)
                .then(response => response.json())
                .then(data => { 
                    that.updateWeatherData(data);                    
                })
                .catch(error => {
                    console.log('Error: ', error)
                });
        },
        round(temperature) {
            return Math.ceil(temperature);
        },
        updateWeatherData(data) {
            let now = new Date;
            
            this.lastUpdatedDateTime = now.toLocaleString();
            this.weatherData = data;
            this.temperatureCurrent = data.main.temp ? this.round(data.main.temp) : "??";
            this.temperatureLow = data.main.temp_min ? this.round(data.main.temp_min) : "??";
            this.temperatureHigh = data.main.temp_max ? this.round(data.main.temp_max) : "??";
            this.currentConditions = data.weather[0].main ? data.weather[0].main : "??";
            this.currentConditionsDetail = data.weather[0].description ? data.weather[0].description : "??";
            this.currentConditionsIcon = data.weather[0].icon ? this.urlIcon + data.weather[0].icon + '.png' : ""; // TODO: Failover icon
        },
        simulateWeather() {                        
            this.updateWeatherData({ "coord": { "lon": -81.233, "lat": 42.9834 }, "weather": [ { "id": 803, "main": "Clouds", "description": "broken clouds", "icon": "04d" } ], "base": "stations", "main": { "temp": 22.55, "feels_like": 22.79, "temp_min": 21.1, "temp_max": 23.6, "pressure": 1003, "humidity": 74 }, "visibility": 10000, "wind": { "speed": 0.97, "deg": 128, "gust": 1.64 }, "clouds": { "all": 84 }, "dt": 1657152102, "sys": { "type": 2, "id": 20399, "country": "CA", "sunrise": 1657101149, "sunset": 1657155991 }, "timezone": -14400, "id": 6058560, "name": "London", "cod": 200 });
        },
        titleCase(str) {
            let strParts    = str !== null ? str.split(" ") : [];
            let formatted   = "";

            for(let x=0; x<strParts.length; x++) {
                let curr = strParts[x];
                curr = curr.toLowerCase();
                
                if (curr !== "") {                    
                    let firstChar = curr.slice(0, 1);
                    let remaining = curr.slice(1);
                    firstChar = firstChar.toUpperCase();                           
                    strParts[x] = firstChar + remaining;                    
                }
            }

            formatted = strParts.join(" ");
            
            return formatted
        }
    },
    mounted() {   
        var that = this;

        clearInterval(this.intervalRef);

        if (this.simulateResponse) {
            console.log("simulating response from weather API");
            this.simulateWeather();
        }   
        else {  
            this.getWeather();

            this.debug.timerInfo = "First refresh interval in " + ( that.updateIntervalMS / 1000 ).toString() + " seconds";

            this.intervalRef = setInterval(function() {
                let next = new Date;                
                next.setTime(next.getTime() + that.updateIntervalMS);                
                console.log("Updating weather");
                that.getWeather();
                console.log("Next update: ", next.toLocaleString());
                that.debug.timerInfo = "Next at " + next.toLocaleString();
                
            }, that.updateIntervalMS);          
            // this.getWeather();        
        }
    }
}
</script>

<style>
.currentTemp {
    display: flex;
    flex-direction: row;
    white-space: nowrap; 
}

.heading {
    font-size: 36px;
    font-weight: 100;
    margin-right: 8pt;
}
</style>