<template>
  <div>
    <v-toolbar height="80" elevation="4" class="d-flex justify-space-around">
        <v-btn 
            class="mx-3" 
            fab 
            dark             
            color="primary" to="/home"
        >
            <v-icon dark>mdi-home</v-icon>
        </v-btn>

        <v-btn
            class="mx-3"
            fab
            dark            
            color="primary" to="/calendar"
        >
            <v-icon dark>mdi-calendar</v-icon>            
        </v-btn>

        <v-btn
            class="mx-3"
            fab
            dark            
            color="primary" to="/reminders"
        >
            <v-icon dark>mdi-reminder</v-icon>
        </v-btn>

        <v-btn
            class="mx-3"
            fab
            dark            
            color="primary" to="/menu"
        >
            <v-icon dark>
                mdi-silverware-fork-knife
            </v-icon>
        </v-btn>
    </v-toolbar>

    <router-view />
  </div>
</template>

<script>
export default {
    name: 'uxMobile',
    data() {
        return {
            items: [
                 { 
                    label: "Home",
                    route:  "/",
                    icon: 'mdi-home',
                    color: '#2196F3'
                },
                { 
                    label : "Dashboard", 
                    route : '/dashboard', 
                    icon : "mdi-view-dashboard"
                },                
                { 
                    label: "About",
                    route:  "/about",
                    icon: 'mdi-information',
                    color: '#3F51B5'
                }
            ]
        }
    },
    methods: {

    },
    mounted() {
    },
    created() {
        if (this.$route.path != "/home") {
            this.$router.push('/home');      
        }  
    }
}
</script>

<style>

</style>