<template>
    <v-card>
        <v-card-title>Calendar</v-card-title>

        <v-card-text>

            <v-btn color="primary" v-if="gapiInited && gsiInited" @click="handleAuthClick">{{ buttonAuthLabel }}</v-btn>
            &nbsp;
            <v-btn color="primary" v-if="showSignOut">Sign Out</v-btn>
            &nbsp;
            <v-btn color="red" @click="listUpcomingEvents">List Upcoming Events</v-btn>

            <div v-if="calEvents.length > 0">                
                <h3>Events</h3>
                <div v-for="item in calEvents" :key="item.id">                    
                    <v-row>
                        <v-col cols="12">
                            <strong>{{ ( item.start.dateTime || item.start.date ) }} : {{ item.summary }}</strong>
                        </v-col>                        
                    </v-row>
                </div>
            </div>
            <div v-else>
                No events. Refresh, maybe?
            </div>

        </v-card-text>

    </v-card>
</template>

<script>
const CLIENT_ID = "56931320245-cl86bb82d79hc9o51evulvpipms3bpo3.apps.googleusercontent.com";
const API_KEY = "AIzaSyA0v5RsFoOBK_eqTnra1DKitSUVqiVcQXg";
const DISCOVERY_DOC = "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
const SCOPES = "https://www.googleapis.com/auth/calendar.readonly";

export default {
    name: 'CalendarNew',
    data() {
        return {
            buttonAuthLabel : "Authorize",
            google : "",
            gapi : "",
            gsiInited : false,
            gapiInited : false,
            tokenClient : "",
            showSignOut : false,
            calendarEvents : "",
            calEvents : []
        }
    },
    methods: { 
        gapiLoaded() {
            this.gapi.load('client', this.initializeGapiClient)
        },
        gsiLoaded() {
            this.tokenClient = this.google.accounts.oauth2.initTokenClient({
                client_id: CLIENT_ID,
                scope: SCOPES,
                callback: '', // defined later
            });
            
            this.gsiInited = true;
            console.log("gsiInited", this.gsiInited);
        },
        async initializeGapiClient() {
            let that = this; 
            await that.gapi.client.init({
                apiKey: API_KEY,
                discoveryDocs: [DISCOVERY_DOC],
            });
        
            this.gapiInited = true;
            console.log("gapiInited", this.gapiInited);
            //maybeEnableButtons();
        },
        handleAuthClick() {
            let that = this;
            
            console.log("handleAuthClick()")

            this.tokenClient.callback = async (resp) => {
                if (resp.error !== undefined) {
                    throw (resp);
                }

                that.showSignOut = true;                    
                that.buttonAuthLabel = "Refresh";
                
                await that.listUpcomingEvents();
                
            };

            if (this.gapi.client.getToken() === null) {
                // Prompt the user to select a Google Account and ask for consent to share their data
                // when establishing a new session.
            
                this.tokenClient.requestAccessToken({prompt: 'consent'});
            } 
            else {
                // Skip display of account chooser and consent dialog for an existing session.
                this.tokenClient.requestAccessToken({prompt: ''});
            }
        },
        async listUpcomingEvents() {
            let response;

            console.log("listUpcomingEvents()");

            try {
                const request = {
                    'calendarId': 'primary',
                    'timeMin': (new Date()).toISOString(),
                    'showDeleted': false,
                    'singleEvents': true,
                    'maxResults': 10,
                    'orderBy': 'startTime',
                };
                
                response = await this.gapi.client.calendar.events.list(request);
            } 
            catch (err) {
                console.log("error", err.message);                
                return;
            }

            const events = response.result.items;
            
            if (!events || events.length == 0) {
                this.calendarEvents = 'No events found.';
                return;
            }
            // Flatten to string to display
            console.log(events);
            const output = events.reduce(
                (str, event) => `${str}${event.summary} (${event.start.dateTime || event.start.date})\n`,
                'Events:\n');
            
            this.calendarEvents = output;
            this.calEvents = events;
        }
    },
    mounted() {
        let scriptAPI = document.createElement('script');
        let scriptClient = document.createElement('script');
        let that = this;

        scriptClient.setAttribute('src', 'https://accounts.google.com/gsi/client');
        scriptClient.onload = () => {
            console.log("scriptClient loaded");
            that.google = window.google;
            that.gsiLoaded();
        }

        document.head.appendChild(scriptClient);

        scriptAPI.setAttribute('src', 'https://apis.google.com/js/api.js');
        scriptAPI.onload = () => {
            console.log("scriptAPI loaded");
            that.gapi = window.gapi;
            that.gapiLoaded();
        }

        document.head.appendChild(scriptAPI);
    }
}
</script>

<style>

</style>