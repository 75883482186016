<template>
    <v-card>

        <v-dialog v-model="dialogAddReminder">
            <v-card>
                <v-card-title>Add Reminder</v-card-title>
                <v-card-text>                    
                    <v-text-field v-model="reminderText" autofocus label="Reminder"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>                   
                    <v-btn                        
                        @click="dialogAddReminder = false" 
                        text              
                    >
                        Close
                    </v-btn>
                     <v-btn                                                                                            
                        dark 
                        @click="insertReminder(); dialogAddReminder = false"  
                        :disabled="reminderText == ''"                                  
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEditReminder">
            <v-card>
                <v-card-title>Edit Reminder</v-card-title>
                <v-card-text>  
                    <v-row>
                        <v-col cols="12">
                            <v-text-field                                                                                                        
                                v-model="selectedReminder.reminder"                                    
                            >                            
                            </v-text-field>                            
                        </v-col>
                    </v-row>                        
                    
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="warning"
                        icon
                        @click="deleteReminder(selectedReminder); dialogEditReminder = false"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>                    
                    <v-btn                        
                        @click="dialogEditReminder = false"                         
                        text                
                    >
                        Close
                    </v-btn>
                    <v-btn                                                                                                                    
                        @click="updateReminder(selectedReminder)"  
                        dark
                    >
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card-title>
            Reminders
        </v-card-title>
        
        <v-card-text>
            <v-list v-if="reminders.length > 0">
                <v-list-item-group                    
                    color="primary"
                >
                    <v-list-item v-for="item in reminders" :key="item.id" @click="selectedReminder = item; dialogEditReminder = true">                           
                        {{ item.reminder }}                             
                    </v-list-item>
                </v-list-item-group>  
            </v-list>
            <div v-else>
                No reminders have been created.
            </div>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn icon color="primary" @click="dialogAddReminder = true">
                    <v-icon>mdi-calendar-plus</v-icon>                                
                </v-btn>   
            </v-card-actions>            
        </v-card-text>
    </v-card>
</template>

<script>
import { db, collection, doc, getDoc, addDoc, setDoc } from '@/fb-db';
import { User } from '@/User';

export default {
    name: 'Reminders',
    data() {
        return {
            account: {
                uid: null
            },
            dialogAddReminder: false,
            dialogEditReminder: false,
            selectedReminder : {},
            reminderText : null,
            reminders: []
        }
    },
    methods: {             
        async getReminders(db) {
            const docRef = doc(db, "reminders", this.account.uid);
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();
            
            if (data.reminders) {   
                this.reminders = data.reminders;                
            }
        },
        insertReminder() {
            let now = new Date;
            let ts  = now.getTime();
            let reminder = {
                id: ts,
                reminder: this.reminderText
            }   
            
            this.reminders.push(reminder);            
            this.updateReminders()

            // clear model
            this.reminderText = "";
        },   
        updateReminder(reminder) {                        
            console.log(this.selectedReminder.reminder);                      
            this.updateReminders();
        },
        deleteReminder(reminder) {  
            let i, x; 
            let curr;            
            
            for(x=0; x<this.reminders.length; x++) {
                curr = this.reminders[x];
                i = curr.id == reminder.id ? x : i;
            }
            
            this.reminders.splice(i, 1);
            this.updateReminders();

        },
        async updateReminders() {
            await setDoc(doc(db, "reminders", this.account.uid), {                
                uid: this.account.uid,
                reminders: this.reminders                
            }, { merge: true});

            // TODO: check result, display message
            //this.updateInProgress = false;  
        },   
    },
    mounted() {
        let user = new User();
        this.account.uid = user.uid;    
        
        console.log("getting reminders...");
        this.getReminders(db)
    }
}
</script>

<style>
</style>