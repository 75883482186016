<template>   
    
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">                     
                     <v-card-title>Sign In</v-card-title>
                     <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field prepend-inner-icon="mdi-at" hint="username" solo single-line v-model="email"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field prepend-inner-icon="mdi-key-variant" hint="password" solo single-line type="password" @keyup.enter="authenticate" v-model="password"></v-text-field>
                                <span v-if="message">
                                    <v-alert dense :type="messageType">
                                        {{ message }}
                                    </v-alert>
                                </span>
                            </v-col>                            
                            <v-col cols="12">                            
                                <v-btn color="primary" @click="authenticate" :disabled="credentialsEntered">Sign In</v-btn>
                                <span class="float-right" v-if="devMode">
                                    <v-btn color="secondary" @click="temp">I'm too busy!</v-btn>                                    
                                </span>                            
                            </v-col>                            
                        </v-row>    
                     </v-card-text>
                  </v-card>                
               </v-flex>
            </v-layout>            
         </v-container>
    
</template>

<script>
import { auth, signInWithEmailAndPassword } from '@/fb-auth';
import { User } from '../User';

export default {
    name: 'SignIn',
    data() {
        return {
            email : "",
            password : "",
            message : "",
            messageType : "warning",
            devMode : false
        }
    },
    methods: {
        showMessage(message, isInfo) {
            this.message = message;
            this.messageType = isInfo ? "info" : "warning";
        },
        authenticate() {            
            if (!this.email || !this.password) {
                this.showMessage("Please enter your credentials", 0);
                return
            }
            
            this.showMessage("Authenticating...", 1);

            signInWithEmailAndPassword(auth, this.email, this.password)
                .then((userCredential) => {
                // Signed in 
                    let user = new User (userCredential.user);                                     
                    this.$emit('isAuthenticated', true);
                // ...
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    if (errorMessage.toUpperCase().indexOf("AUTH") > 0) {                                                
                        this.showMessage("Authentication failed. Invalid credentials...", 0);
                    }
                    console.log(errorMessage);
                });
        },
        writeSessionData(data) {
            sessionStorage.setItem("appdata", JSON.stringify(data));
        },
        temp() {            
            this.$emit('isAuthenticated', true);
        }        
    },
    computed: {
        credentialsEntered() {             
            return this.email != "" && this.password != "" ? false : true;
        }       
    },
    mounted() {         
        var session = sessionStorage.getItem("appdata") || "{}";
        this.devMode = window.location.host.toUpperCase().indexOf("LOCALHOST") == 0 ? true : false;    
        
        session = JSON.parse(session);

        if (session.email) {
            this.$emit('isAuthenticated', true);
        }
        
    }
}
</script>

<style>

</style>
