<template>
  <v-container fluid>

    <v-dialog      
      max-width="600"
      v-model="dialogConfirm"
    >
      <v-card>
        <v-card-title>Sign Out?</v-card-title>
        <v-card-text>
          For realz?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="signOut">OK</v-btn>
          <v-btn @click="dialogConfirm = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title>Account</v-card-title>
      
      <v-card-text>

        <v-row>

          <v-col cols="2">
            <v-avatar>
              <v-img src="../assets/avatars/hotdog-01.png"></v-img>              
            </v-avatar>
          </v-col>

          <v-col cols="10">            
            <v-simple-table>          
                <tbody>
                  <tr>
                    <td>Account</td>
                    <td>{{ account.email }}</td>
                  </tr>
                  <tr v-if="devMode">
                    <td>uid</td>
                    <td>{{ account.uid }}</td>
                  </tr>
                </tbody>    
            </v-simple-table>
          </v-col>
        </v-row>
 
        <v-card-actions>          
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogConfirm = true">Sign Out</v-btn>
        </v-card-actions>


      </v-card-text>

  </v-card>

  </v-container>
</template>

<script>
import { User } from '../User';
import { db, collection, getDocs } from '@/fb-db';

export default {
    name: 'Account',
    data() {
        return {
          account : {
            uid : null,
            email : null,
          },          
          devMode : false,
          dialogConfirm : false
        }
    },
    methods: {
      dbTest() {
            this.getData(db);
      },
      async getData(db) {
          const uid = "PASTE UID HERE";
          const settingsColl = collection(db, 'user-settings'); 
          const settingsDocs = await getDocs(settingsColl);
          const snizzList = settingsDocs.docs.map(doc => doc.data());
          console.log(snizzList);            
          // TODO: obtain UID from session storage object          
      },
      signOut() {    
        let user = new User();
        user.sessionDestroy();     
        this.$emit('isAuthenticated', false);   
        this.dialogConfirm = false;
        document.location.href = "#/";        
      }      
    },
    mounted() {      
      let user = new User();

      this.account.uid = user.uid ? user.uid : null;
      this.account.email = user.email ? user.email : null;

      this.devMode = window.location.host.toUpperCase().indexOf("LOCALHOST") == 0 ? true : false; 
      
      console.log("user getUID", user.getUID());
      console.log("user email", user.email);
      console.log("user uid", user.uid);
      console.log(user);     

    }
}
</script>

<style>

</style>