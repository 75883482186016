<template>
    <v-container>        
        <v-row class="pt-2">            
            <v-col cols="12" class="pt-2">
                <Today/>
            </v-col>

            <v-col cols="12" class="pt-2">
                <Events />
            </v-col>

            <v-col cols="12" class="pt-2">
                <Menu :summary=showSummary />
            </v-col>

            <v-col cols="12" class="pt-2">
                <Weather :summary=showSummary />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Today from "./Today"
import Events from "./Events"
import Weather from "./Weather.vue"
import Menu from "./Menu.vue"

export default {
    name: 'Home',
    components: {
        Today,
        Events,
        Weather,
        Menu
    },
    data: () => ({       
        showSummary: true
    }),
    methods: {

    },
    created() {

    },
    beforeDestroy() {
    }
}
</script>

<style>

</style>