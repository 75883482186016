<template>
    <v-card>
        <v-card-title>
            Events
        </v-card-title>
        <v-card-subtitle>
            Events for today, tomorrow, whatevah.
        </v-card-subtitle>
        <v-card-text>
            
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'Events',
    data: () => ({
    }),
    methods: {        
    },
    created() {
    },
    beforeDestroy() {
    }
}
</script>

<style>
</style>