<template>
    <v-card>
        <v-card-title>
            {{ currentDate }} 
            <v-spacer></v-spacer>
            {{ currentTime }}
        </v-card-title>
        <v-card-subtitle>
            Summary of today's ness
        </v-card-subtitle>
    </v-card>
</template>

<script>
export default {
    name: 'Today',
    data: () => ({
        months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
        weekdays: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
        currentTime: "",
        currentDate: "",
        intervalID: null,        
        debugInterval: false,
        updateRefreshIntervalMS: 5000,
        shortWeekday: true,
        showWeekday: true,
        shortMonth: true
    }),
    methods: {
        setCurrentDate() {
            let now     = new Date;
            let weekday = !this.showWeekday ? "" : this.shortWeekday ? this.weekdays[now.getDay()].substring(0, 3) + ", " : this.weekdays[now.getDay()] + ", ";
            let month   = this.shortMonth ? this.months[now.getMonth()].substring(0, 3) : this.months[now.getMonth()];
            
            if (!this.showWeekday) {
                //weekday = "";
            }
            
            this.currentDate = weekday + month + " " + now.getDate().toString() + ", " + now.getFullYear().toString();                                 
        },
        setCurrentTime() {
            let now = new Date;
            let hh = now.getHours() > 12 ? (now.getHours() - 12).toString() : now.getHours().toString();
            let mm = now.getMinutes() < 10 ? "0" + now.getMinutes().toString() : now.getMinutes().toString();
            let xm = now.getHours() >= 12 ? "PM" : "AM";           
            
            this.currentTime = hh + ":" + mm + " " + xm;                        
        },
        refreshDateTime() {
            this.intervalID = setInterval(() => {
                let now = new Date;
                this.setCurrentDate();
                this.setCurrentTime();
                if (this.debugInterval) {
                    console.log("Home interval timer is running with id", this.intervalID, "at timestamp", now.getTime());
                }
            }, this.updateRefreshIntervalMS);
        }
    },
    created() {           
        this.setCurrentDate();
        this.setCurrentTime();
        this.refreshDateTime();
    },
    beforeDestroy() {
        clearInterval(this.intervalID);
    }
}
</script>

<style>
</style>