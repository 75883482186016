class User {
    #storageKey = "user-session";

    constructor(initData) {    
        if (typeof initData === "object") {
            // write session data                    
            this.sessionWrite(initData);            
        }
        
        this.sessionData = this.sessionRead();                          
       
        if (!this.sessionData["uid"] || this.sessionData["uid"] == null) {
            console.log("WARNING: User uid is null or missing!");
        }
    }

    sessionRead() {        
        let sessionData = sessionStorage.getItem(this.#storageKey) || "{}";    
        sessionData = JSON.parse(sessionData);        
        return sessionData; 
    }

    sessionWrite(data) {        
        sessionStorage.setItem(this.#storageKey, JSON.stringify(data));        
    }

    sessionDestroy() {        
        sessionStorage.removeItem(this.#storageKey);
    }

    get uid() {
        return this.sessionData.uid || null;
    }

    get email() {
        return this.sessionData.email || null;
    }

    get valid() {
        let uidValid    = this.sessionData.uid && this.sessionData.uid !== null ? true : false;
        let emailValid  = this.sessionData.email && this.sessionData.email !== null ? true : false;
        return uidValid && emailValid ? true : false;
    }

    getUID() {
        return this.sessionData.uid
    }
}

export { User }