<template>
  <v-container fluid>    
    
    <v-row>
        <v-col cols="12" md="6" sm="12">
            <CalendarNew />
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <Weather />
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" md="6" sm="12">
            <Reminders />
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <Menu />
        </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
import CalendarNew from "./CalendarNew"
import Weather from "./Weather"
import Menu from "./Menu"
import Reminders from "./Reminders"

export default {
    name: 'Dashboard',
    components: {
        CalendarNew,
        Weather,
        Menu,
        Reminders        
    },
    data() {
        return {

        }
    },
    methods: {}
}
</script>

<style>

</style>